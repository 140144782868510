import React, { useState, useEffect, useRef } from 'react';
// import { navigate } from 'gatsby'
import Layout from '../components/Layout';
import Mapbox from '../mapbox'
import Chart from '../components/Chart';
import Calculate from '../util/calculate';

import { Select } from 'antd';
import './index.css';

import TumbonAddr from '../util/TumbonAddr'

import newnew from '/images/wepik-photo-mode-2022619-143425.webp';

import api from '../util/api';

const { Option } = Select;

const MAP_INDEX = [
  'ภาวะความพึ่งพิง',
  'ที่พักอาศัย',
  'ห้องน้ำ',
  'ที่ดินทำกิน',
  'แหล่งน้ำ',
  'แหล่งไฟฟ้า',
  'ยานพาหนะ',
  'เครื่องใช้ไฟฟ้า',
  'ระยะทาง',
  'ผลการเรียน',
  'PMT',
]

const PageAnalytic = (props) => {
  const [initData, setInitData] = useState(null)
  const [data_map, setDataMap] = useState([{ id: 'border', layer: 'country', data: [] }])
  const [list_province, setListProvince] = useState([])
  const [filter, setFilter] = React.useState({
    view: 'country',
    year: "2564",
    semester: "2",
    indicator: 0,
    province_name: "ทั้งหมด",
    province_chart: "กระบี่",
    city_name: null,
    tumbon_name: null,
  })

  const is_Click = useRef(false);
  const [data_mapcolor] = useState(['#1b5e20', '#388e3c', '#66bb6a', '#a5d6a7', '#94a3b8'])
  const [data_chart_province, setDataChartProvince] = useState({ pcc1: [0, 0, 0, 0, 0, 0, 0, 0], pcc2: [0, 0, 0] })
  const [data_chart_type, setDataChartType] = useState([])

  useEffect(async () => {
    let dataSummaryAllyear = await api.getInitData("dashboard-analytics");
    setInitData(dataSummaryAllyear.data);
  }, [])

  useEffect(() => {
    if (initData) {
      let dataContext = initData
      let response = onProcessData({
        province: filter['province_name'],
        city: filter['city_name'],
        tumbon: filter['tumbon_name'],
        layer: filter['view']
      }, convertMapContent(filter['view'], dataContext, filter.indicator))
      setDataChartProvince({
        pcc1: dataContext.filter(item => item.province_name === filter.province_chart)[0].pcc.slice(0, 8),
        pcc2: dataContext.filter(item => item.province_name === filter.province_chart)[0].pcc.slice(8, 11).map((item, i) => i === 1 ? Math.abs(item) : item),
      })
      setListProvince(dataContext.map(item => item.province_name).sort(function (a, b) {
        if (a < b) { return -1; }
        if (a > b) { return 1; }
        return 0;
      }))
      let data_chart = []
      JSON.parse(JSON.stringify(dataContext)).forEach(item => {
        if (filter.indicator === 9) {
          item.pcc[filter.indicator] = Math.abs(item.pcc[filter.indicator])
        }
        data_chart.push(item)
      })
      setDataChartType(data_chart.sort((a, b) => b.pcc[filter.indicator] - a.pcc[filter.indicator]))
      setDataMap([
        { id: 'border', layer: filter.view, data: response.data },
      ])
    }
  }, [filter, initData])

  const convertMapContent = (layer, data, indicator) => {
    let res = []
    const province = [...new Set(data.map(item => item.province_name))].map(item => { return { province: item, total: 0, indicator: 0 } });
    data.forEach((item) => {
      let index = province.findIndex(ele => ele.province === item.province_name)
      if (indicator === 9) {
        province[index]['indicator'] = (item.pcc[indicator] < 0 ? Math.abs(item.pcc[indicator]) : item.pcc[indicator] > 0 ? -(item.pcc[indicator]) : 0)
      } else {
        province[index]['indicator'] = item.pcc[indicator]
      }
      res = province

    })
    return res
  }

  const onProcessData = (filter, data) => {
    const _genColorRange = (color_set, value) => {
      color_set = color_set.sort((a, b) => b.value - a.value)
      let color = ''
      for (let i in color_set) {
        if (value === 0) {
          color = data_mapcolor[4]
        } else if (value > color_set[i].value) {
          color = color_set[i].color
          break;
        }
      }
      return color
    }

    let result = []
    data.forEach(element => {
      if (filter.province && filter.province !== 'ทั้งหมด' && !filter.city && !filter.tumbon) {
        if (filter.province === element.province) {
          result.push(element)
        }
      } else if (filter.province && filter.city && !filter.tumbon) {
        if (filter.province === element.province && filter.city === element.city) {
          result.push(element)
        }
      } else if (filter.province && filter.city && filter.tumbon) {
        if (filter.province === element.province && filter.city === element.city && filter.tumbon === element.tumbon) {
          result.push(element)
        }
      } else {
        result.push(element)
      }
    })

    // result.map(element => {
    //   if (!isFinite(element['indicator'] / element['total'])) {
    //     element['percent'] = 0
    //   } else {
    //     element['percent'] = ((element['eef_help'] / result.map(item => item.eef_help).reduce((a, b) => a + b, 0)) * 100)
    //   }
    //   return element
    // })

    let data_color = [
      { color: data_mapcolor[0], value: Calculate.Percentile(90, result.map(item => item.indicator)), count: 0, text: `มาก` },
      { color: data_mapcolor[1], value: Calculate.Percentile(50, result.map(item => item.indicator)), count: 0, text: `ปานกลาง` },
      { color: data_mapcolor[2], value: Calculate.Percentile(10, result.map(item => item.indicator)), count: 0, text: `ปานกลาง` },
      { color: data_mapcolor[3], value: -1, count: 0, text: `น้อย` },
    ]

    result.forEach(element => {
      element['color'] = _genColorRange(data_color, element.indicator)
      // element['percent'] = parseFloat(element['percent'].toFixed(2))
    })
    let res = { data: result, notic: data_color }
    return res
  }
  return (
    <Layout Role={['eef']}>
      < div className='bg-gray-200'>
        <div style={{
          width: '100%',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          height: '100vh',
          backgroundImage: `url(${newnew})`,
          display: 'flex'

        }} >
          <h1 className="font-bold text-2xl md:text-3xl xl:text-5xl text-center text-white mt-[120px] w-full ">การวิเคราะห์ข้อมูล PCC</h1>
        </div>
        <div className="max-w-[1300px] rounded-3xl m-auto -mt-[70vh] p-2 md:p-4 lg:8 bg-white bordered">
          <div className="font-bold text-xl lg:text-3xl mb-2 text-[#0E3165] text-center">การวิเคราะห์ข้อมูลนักเรียนทุนเสมอภาครายจังหวัด</div>

          <div className='grid grid-cols-2 gap-4'>
            <div className="col-span-2 justify-center">
              <div className="flex flex-col p-0 m-0">
                < div className="flex flex-row" >
                  <div>
                    <h5 className="text-sm m-0 ml-[9px]" >จังหวัด: </h5>
                    <Select
                      showSearch
                      placeholder="ประเภท PCC :"
                      value={filter.province_chart}
                      style={{ width: 180, margin: 4 }}
                      onChange={(val) => {
                        setFilter({ ...filter, province_chart: val })
                      }}>
                      {
                        list_province.map((x, i) => <Option value={x}>{x}</Option>)
                      }
                    </Select>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-span-2 xl:col-span-1'>
              <Chart
                containerProps={{ style: { height: "500px" } }}
                className='p-4'
                options={{
                  type: 'spiderWeb',
                  title: 'ปัจจัยสถานะครัวเรือนที่มีผลต่อคะแนน PMT',
                  categories: MAP_INDEX.slice(0, 8),
                  yAxis: {
                    gridLineInterpolation: 'polygon',
                    labels: {
                      formatter: function () {
                        return this.value;
                      }
                    },
                    floor: Math.min(...data_chart_province.pcc1) >= 0 ? 0 : Math.min(...data_chart_province.pcc1),
                    ceiling: Math.max(...data_chart_province.pcc1),
                    min: Math.min(...data_chart_province.pcc1) >= 0 ? 0 : Math.min(...data_chart_province.pcc1),
                    max: Math.max(...data_chart_province.pcc1),
                    lineWidth: 0,
                  },
                }}
                data={
                  [{
                    name: 'ค่าสหสัมพันธ์ (Pearson correlation)',
                    data: data_chart_province.pcc1.map(item => { return (item < 0 ? 0 : item) }),
                    pointPlacement: 'on'
                  }]
                } />
            </div>
            <div className='col-span-2 xl:col-span-1'>
              <Chart
                containerProps={{ style: { height: "500px" } }}
                className='p-4'
                options={{
                  type: 'customchart',
                  chart: {
                    chart: {
                      type: 'columnrange',
                      style: {
                        fontFamily: 'Kanit'
                      },
                      inverted: true
                    },
                    title: {
                      text: 'ความสัมพันธ์ข้อมูลการขาดเรียนกับข้อมูลอื่นๆ',
                    },
                    xAxis: {
                      categories: MAP_INDEX.slice(8, 11).map(item => '%การขาดเรียน vs ' + item),
                    },
                    yAxis: {
                      title: {
                        text: 'ค่าสหสัมพันธ์ (Pearson correlation)'
                      },
                      labels: {
                        formatter: function () {
                          return this.value;
                        }
                      },
                      floor: Math.min(...data_chart_province.pcc2) >= 0 ? 0 : Math.min(...data_chart_province.pcc2),
                      ceiling: Math.max(...data_chart_province.pcc2),
                      min: Math.min(...data_chart_province.pcc2) >= 0 ? 0 : Math.min(...data_chart_province.pcc2),
                      max: Math.max(...data_chart_province.pcc2),
                      plotLines: [{
                        value: 0,
                        zIndex: 5,
                        width: 2,
                        color: 'black'
                      }]
                    },
                    tooltip: {
                      formatter: function () {
                        return 'ค่าสหสัมพันธ์ : ' + data_chart_province.pcc2[this.point.index];
                      }
                    },
                    legend: {
                      enabled: false
                    },
                    credits: {
                      enabled: false
                    },
                    series: [{
                      name: 'ค่าสหสัมพันธ์ (Pearson correlation)',
                      data: data_chart_province.pcc2.map(item => item > 0 ? [0, item] : item < 0 ? [item, 0] : [0, 0])
                    }]
                  }
                }} />
            </div>
          </div>

          <div className="max-w rounded-xl overflow-hidden shadow-[0_0px_16px_rgba(17,17,26,0.1)] bg-white w-full mt-10 p-2">

            <header className="text-gray-600 body-font">
              <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
                <nav className="flex lg:w-1/5 flex-wrap items-center text-base md:ml-auto">
                </nav>
                <p className="w-full items-center justify-center font-bold text-2xl mb-2  text-[#048967] text-center">
                  แผนที่ความสัมพันธ์ของข้อมูลจากฐานข้อมูลทุนเสมอภาค
                </p>
              </div >
            </header >

            <div className="w-full h-[1px] bg-gray-300 mb-4" > </div>

            <div className="grid grid-cols-11 gap-4 w-full">
              <div className="col-span-11 justify-center">
                <div className="flex flex-col p-0 m-0">
                  < div className="flex flex-row" >
                    <div>
                      <h5 className="text-sm m-0 ml-[9px]" >รายการวิเคราะห์ข้อมูล: </h5>
                      <Select
                        showSearch
                        placeholder="รายการวิเคราะห์ข้อมูล"
                        value={`ความสัมพันธ์ระหว่าง ${MAP_INDEX[filter.indicator]} กับ${filter.indicator >= 8 ? ' %การขาดเรียน' : 'คะแนน PMT'}`}
                        style={{ width: 400, margin: 4 }}
                        onChange={(val) => {
                          setFilter({ ...filter, indicator: val })
                        }}>
                        {
                          MAP_INDEX.map((x, i) => <Option value={i}>{`ความสัมพันธ์ระหว่าง ${x} กับ${i >= 8 ? ' %การขาดเรียน' : 'คะแนน PMT'}`}</Option>)
                        }
                      </Select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-span-11">
                <div className="relative w-full h-full rounded overflow-hidden shadow-card bg-slate-500">
                  <Mapbox
                    id={'map-pms'}
                    data={data_map}
                    zoom={5.2}
                    className="map-pms-container"
                    style={"border_basic"}
                    filter={filter}
                    notic_title={'จำนวนพื้นที่ดำเนินงานทั้งหมดของ กสศ.'}
                  // onclickBorder={onclickBorder}
                  />
                  <div className='absolute bottom-[50px] md:bottom-[10px] left-[100px] z-1 flex'>
                    <div className='h-full rounded-lg flex flex-col shadow-xl bg-white px-2 py-3'>
                      <span className='font-bold center mx-1 mb-2'>สัดส่วนการวิเคราะห์ข้อมูลตาม PCC</span>
                      <div className='flex'>
                        <div className='flex flex-col'>
                          <div class={`h-3 w-10 md:w-16 bg-[#1b5e20]`}>
                          </div>
                          <div class="font-bold text-left">
                            มาก
                          </div>
                        </div>
                        <div className='flex flex-col'>
                          <div class={`h-3 w-10 md:w-16 bg-[#388e3c]`}>
                          </div>
                        </div>
                        <div className='flex flex-col'>
                          <div class={`h-3 w-10 md:w-16 bg-[#66bb6a]`}>
                          </div>
                        </div>
                        <div className='flex flex-col'>
                          <div class={`h-3 w-10 md:w-16 bg-[#a5d6a7]`}>
                          </div>
                          <div class="font-bold text-right">
                            น้อย
                          </div>
                        </div>
                        <div className='ml-4 flex flex-col'>
                          <div class={`h-3 w-10 md:w-16 bg-[#94a3b8]`}>
                          </div>
                          <div class="font-bold text-center">
                            ไม่มีข้อมูล
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div className='grid grid-cols-1 gap-4'>
            <div className='col-span-1'>
              <Chart
                containerProps={{ style: { height: "500px" } }}
                className='p-4'
                options={{
                  type: 'column',
                  title: 'ความสัมพันธ์ของข้อมูลจากฐานข้อมูลทุนเสมอภาค',
                  yAxis: {
                    title: {
                      text: 'ค่าสหสัมพันธ์ (Pearson correlation)'
                    },
                    labels: {
                      formatter: function () {
                        return this.value;
                      }
                    },
                    min: ((filter.indicator === 8 || filter.indicator === 10) ? Math.min(...data_chart_type.map(item => item.pcc[filter.indicator])) : 0),
                    floor: ((filter.indicator === 8 || filter.indicator === 10) ? Math.min(...data_chart_type.map(item => item.pcc[filter.indicator])) : 0),
                  },
                  xAxis: {
                    type: 'category',
                    crosshair: true,
                    categories: data_chart_type.map(item => item.province_name),
                    labels: {
                      rotation: -90,
                    }
                  },
                  plotOptions: {
                    column: {
                      // pointPadding: 0.05,
                      borderWidth: 0
                    },
                  },
                }}
                data={
                  [{
                    name: 'รายการวิเคราะห์ข้อมูล',
                    // data: (filter.indicator === 9 ? data_chart_type.map(item => Math.abs(item.pcc[filter.indicator])) : data_chart_type.map(item => item.pcc[filter.indicator])) ,
                    data: data_chart_type.map(item => item.pcc[filter.indicator]),
                  }]
                } />
            </div>
          </div>

        </div >
      </div >
    </Layout >
  )

}

export default PageAnalytic
